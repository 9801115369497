import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import Radio from '@mui/material/Radio';
import { RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useFormContext } from 'react-hook-form';
import giftCardDetailsPageMessages from './messages/giftCardDetailsPageMessages';

export const DeliveryMethodRadio = ({ intl, eGiftCardShareOption, onRadioChange }) => {
    const formValidation = useFormContext();
    const { register, formState: { errors } } = formValidation;
    return (
        <FormControl className='egift-delivery-option-wrpr'>
            <FormLabel className='egift-d-o-label'>{giftCardDetailsPageMessages.sendViaDetailsTitle.defaultMessage}</FormLabel>
            <RadioGroup
                value={eGiftCardShareOption}
                name="radio-buttons-group"
                onChange={onRadioChange}
            >
                <FormControlLabel
                    value="sharable"
                    data-testid="radio-sharable"
                    control={<Radio />}
                    {...register('eGiftCardShareOption', { required: 'Please select how you would like to share the eGift' })}
                    label={
                        (
                            <>
                                <span className='egift-d-o-label-p1-text'>{intl.formatMessage(giftCardDetailsPageMessages.sendViaSharableLinkText1)}</span>
                                <span className='egift-d-o-label-p2-text'>{intl.formatMessage(giftCardDetailsPageMessages.sendViaSharableLinkText2)}</span>
                            </>
                        )
                    }
                />
                <FormControlLabel
                    value="email"
                    data-testid="radio-email"
                    control={<Radio />}
                    {...register('eGiftCardShareOption', { required: 'Please select how you would like to share the eGift' })}
                    label={intl.formatMessage(giftCardDetailsPageMessages.sendViaEmailText)}
                />
                {errors.eGiftCardShareOption && <p>{errors.eGiftCardShareOption.message}</p>}
            </RadioGroup>
        </FormControl>
    );
};

DeliveryMethodRadio.propTypes = {
    intl: intlShape.isRequired,
    eGiftCardShareOption: PropTypes.string.isRequired,
    onRadioChange: PropTypes.any.isRequired
};

export default injectIntl(DeliveryMethodRadio);
