import { Map as IMap } from 'immutable';
import { purchaseTypeClickedSegmentData } from './segmentSelectors';


export const TRACK_EXTERNAL_LINK_CLICK = '@@storefront/TRACK_EXTERNAL_LINK_CLICK';
export const TRACK_PURCHASE_TYPE = '@@storefront/TRACK_PURCHASE_TYPE';


const purchaseTypeReducer = (state = IMap({}), action) => {
    switch (action.type) {
        case TRACK_PURCHASE_TYPE: {
            const purchaseTypeStatusData = action.purchaseType;
            return state.set('selectedPurchaseType', purchaseTypeStatusData);
        }
        default:
    }
    return state;
};


export function trackExternalLinkClick(eventData) {
    return {
        type: TRACK_EXTERNAL_LINK_CLICK,
        meta: {
            segment: {
                eventAction: 'External Link Clicked',
                eventData
            }
        }
    };
}

export const saveSelectedPurchaseType = purchaseType => ({
    type: TRACK_PURCHASE_TYPE,
    purchaseType,
    meta: {
        segment: {
            eventAction: 'Purchase Type Clicked',
            eventData: purchaseTypeClickedSegmentData(purchaseType)
        }
    }
});

export default purchaseTypeReducer;
