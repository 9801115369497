import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import {
    Typography,
    Box,
    Card,
    CardMedia,
    CardContent
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import orderStatusMessages from '../order/messages/orderStatusMessages';
import promoMessages from '../promotion/messages/promoMessages';
import { EGIFT_DELIVERY_EMAIL } from '../constants/constants';

const OrderStatusLayout = (props) => {
    const {
        intl,
        isPromo,
        purchaseCardValue,
        cardMediaUrl,
        displayInfo
    } = props;
    const promoEmail = displayInfo.senderEmail ? displayInfo.senderEmail : displayInfo.recipientEmail;
    const displayEmail = isPromo ? promoEmail : displayInfo.recipientEmail;
    return (
        <Card className={classNames('order-Status-Card-Container', { 'promo-card-boundary': isPromo })}>
            <CardContent className='order-Status-Card-Details'>
                {cardMediaUrl && (
                    <CardMedia
                        className='order-status-card-image'
                        component='img'
                        image={cardMediaUrl}
                        title="Selected Faceplate"
                    />
                )}
                <ArrowForwardIcon className='order-Status-Card-Arrow' />
                <Typography className='card-details-send-to-h5-variant' variant='h5'>
                    { displayInfo.eGiftCardShareOption === EGIFT_DELIVERY_EMAIL ? displayEmail : `${intl.formatMessage(orderStatusMessages.sendALinkToLabel)} ${displayInfo.recipientName}`}
                </Typography>
                <Box className='order-Status-Card-Value-container'>
                    <Typography className='order-Status-Card-Value'>
                        {purchaseCardValue}
                    </Typography>
                    {isPromo && (
                        <Typography className='order-Status-Promo-free-label'>
                            { intl.formatMessage(promoMessages.freeTitle) }
                        </Typography>
                    )}
                </Box>
            </CardContent>
            { displayInfo.personalMessage && !isPromo
            && (
                <Box className='order-Status-Card-Message-Container'>
                    <Typography className='order-Status-Card-Message-label'>
                        { intl.formatMessage(orderStatusMessages.personalMessageLabel) }
                    </Typography>
                    <Typography className='order-Status-Card-Message'>
                        { displayInfo.personalMessage }
                    </Typography>
                </Box>
            )}
        </Card>
    );
};

OrderStatusLayout.defaultProps = {
    isPromo: false,
    purchaseCardValue: '',
    cardMediaUrl: '',
    displayInfo: null
};

OrderStatusLayout.propTypes = {
    intl: intlShape.isRequired,
    isPromo: PropTypes.bool,
    purchaseCardValue: PropTypes.string,
    cardMediaUrl: PropTypes.string,
    displayInfo: PropTypes.object
};

export default injectIntl(OrderStatusLayout);
