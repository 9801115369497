import { fromJS, Map as IMap, List as IList } from 'immutable';
import { createSelector } from 'reselect';

import {
    HOME_STEP,
    CATEGORY_STEP,
    PDP_STEP,
    PAYMENT,
    ORDER_SUCCESS,
    ORDER_FAILURE
} from '../routing/flowConstants';
import { CONSUMER_PRODUCT_CODE, BILLABLE_PRODUCT_FEATURE_CODE } from '../constants/constants';
import { getBrandCode } from '../brand/brandSelectors';
import { getStorefrontSessionKey } from '../session/sessionSelectors';


export const getPurchaseType = state => state.get('purchaseType', IMap());

export const getSelectedPurchaseType = createSelector(
    getPurchaseType,
    purchaseType => purchaseType.get('selectedPurchaseType', '')
);

/**
 * Map of Analytics Names used for Segment Page Tracking keyed by the pathname to which they apply.
 */
export const analyticsPathNamesMap = {
    [HOME_STEP]: 'Home',
    [CATEGORY_STEP]: 'Category',
    [PDP_STEP]: 'Product Details Page',
    [PAYMENT]: 'Payment',
    [ORDER_SUCCESS]: 'Order Success Page',
    [ORDER_FAILURE]: 'Order Failure Page'
};

export const getAnalyticsPathName = path => analyticsPathNamesMap[path];

export const getBaseEventProperties = state => ({
    buyFlow: 'Storefront_v2',
    productCode: CONSUMER_PRODUCT_CODE,
    brandCode: getBrandCode(state),
    billableProductFeatureCode: BILLABLE_PRODUCT_FEATURE_CODE,
    cashStarSessionId: getStorefrontSessionKey(state),
    latestPurchaseType: getSelectedPurchaseType(state)
});

export const productClickedSegmentData = (faceplate, category) => ({
    product_id: faceplate.get('code', ''),
    sku: '',
    category,
    name: faceplate.get('altText', ''),
    position: faceplate.get('ordinal', ''),
    image_url: faceplate.get('imageURL', '')
});

/**
 * Get the segment defined data for each product
 *
 * @param {Immutable.Map} egc egc - contains information about a single e-gift card
 * @param {Immutable.Map} faceplate faceplate - contains information about faceplate
 * @returns {object} Segment data for each product
 */
export const giftCardItemData = (egc, faceplate) => ({
    product_id: faceplate.get('code', ''),
    sku: '',
    name: faceplate.get('altText', ''),
    price: egc.get('cardValue', ''),
    quantity: 1,
    category: faceplate.get('category', ''),
    variant: egc.get('selfBuy', '') ? 'SELF' : 'GIFT',
    position: faceplate.get('ordinal', ''),
    image_url: faceplate.get('imageURL', ''),
    message: egc.get('personalMessage', '') ? 'Custom' : 'Blank'
});

export const checkoutStartedSegmentData = ({ eGiftCards, currencyCode }) => {
    const immutableEGiftCards = fromJS(eGiftCards);
    return {
        products: immutableEGiftCards.map(egc => giftCardItemData(egc, egc.get('faceplate', IMap()))),
        currency: currencyCode
    };
};

export const orderCompletedSegmentData = ({
    orderData: { orderNumber } = {},
    amount,
    eGiftCards,
    currencyCode,
    sessionKey
}) => {
    const immutableEGiftCards = fromJS(eGiftCards);
    const products = immutableEGiftCards.map(egc => giftCardItemData(egc, egc.get('faceplate', IMap())));
    return {
        checkout_id: sessionKey,
        order_id: orderNumber,
        total: parseFloat(amount),
        revenue: parseFloat(amount),
        shipping: 0.00,
        discount: 0.00,
        currency: currencyCode,
        products
    };
};

export const orderFailedSegmentData = ({
    error = {},
    totalAmount,
    currencyCode,
    eGiftCards = IList()
}) => {
    const products = eGiftCards.map(egc => giftCardItemData(egc, egc.get('faceplate', IMap())));
    return {
        error,
        total: parseFloat(totalAmount),
        currency: currencyCode,
        products
    };
};


export const purchaseTypeClickedSegmentData = purchaseType => ({
    purchaseType
});
