export const XS_BREAKPOINT_CUTOFF = 0;
export const SM_BREAKPOINT_CUTOFF = 768;
export const MD_BREAKPOINT_CUTOFF = 1024;
export const LG_BREAKPOINT_CUTOFF = 1280;
export const XL_BREAKPOINT_CUTOFF = 1600;

export const MAX_SCREEN_WIDTH_CUTOFF = 2600;

export const isMobileview = windowWidth => windowWidth < SM_BREAKPOINT_CUTOFF;

export const isTabView = windowWidth => (
    windowWidth >= SM_BREAKPOINT_CUTOFF && windowWidth < MD_BREAKPOINT_CUTOFF);

export const isDesktopView = windowWidth => windowWidth >= MD_BREAKPOINT_CUTOFF;

export const deviceType = (windowWidth) => {
    switch (true) {
        case isMobileview(windowWidth):
            return 'mobile';
        case isTabView(windowWidth):
            return 'tab';
        default: return 'desktop';
    }
};
