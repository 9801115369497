import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';

import TextInputContainer from '../primitive/input/TextInputContainer';
import InputGroupTitle from '../primitive/input/InputGroupTitle';
import giftCardDetailsPageMessages from './messages/giftCardDetailsPageMessages';
import {
    PERSONAL_MESSAGE,
    PERSONAL_MESSAGE_MAX_LENGTH,
    RECIPIENT_EMAIL,
    RECIPIENT_NAME,
    SENDER_EMAIL,
    SENDER_NAME,
    EGIFT_DELIVERY_EMAIL,
    EGIFT_CARD_SHARE_OPTION,
    EGIFT_DELIVERY_SHAREABLE
} from '../constants/constants';
import { getEmailSchema, getMessageSchema, getNameSchema } from './validation/productDetailsSchema';
import { getProfanityDetectionEnabled, getShareViaLinkDefaultOptionEnabled } from '../brand/brandSelectors';
import { DeliveryMethodRadio } from './DeliveryMethod';

const RecipientEmail = ({ intl }) => (
    <TextInputContainer
        className='pdpFormField recipientEmailField textFieldOutline'
        id='recipientEmailField'
        name={RECIPIENT_EMAIL}
        label={(
            <>
                <span className='requiredAsterisk'>*</span>
                {intl.formatMessage(giftCardDetailsPageMessages.recipientEmailLabel)}
            </>
        )}
        validationSchema={getEmailSchema(intl)}
        helperText={`${intl.formatMessage(giftCardDetailsPageMessages.recipientEmailRemark)}`}
        customPlaceHolder='e.g. email@abc.com'
    />
);

RecipientEmail.propTypes = {
    intl: PropTypes.object.isRequired
};

const RecipientName = ({ intl, isProfanityDetectionEnabled }) => (
    <TextInputContainer
        className='pdpFormField recipientNameField textFieldOutline'
        id='recipientNameField'
        name={RECIPIENT_NAME}
        label={(
            <>
                <span className='requiredAsterisk'>*</span>
                {intl.formatMessage(giftCardDetailsPageMessages.recipientNameLabel)}
            </>
        )}
        validationSchema={getNameSchema(intl, isProfanityDetectionEnabled, RECIPIENT_NAME, giftCardDetailsPageMessages.profaneRecipientName)}
    />
);

RecipientName.propTypes = {
    intl: PropTypes.object.isRequired,
    isProfanityDetectionEnabled: PropTypes.bool.isRequired
};

const PersonalMessage = ({ intl, personalMessage, isProfanityDetectionEnabled }) => {
    const defaultValue = personalMessage || '';
    const [personalMessageValue, setPersonalMessageValue] = React.useState(defaultValue);
    const remainingNumber = PERSONAL_MESSAGE_MAX_LENGTH - personalMessageValue.length;

    const handleMessageChange = (event) => {
        setPersonalMessageValue(event.target.value);
    };

    return (
        <TextInputContainer
            className='pdpFormField messageField textFieldOutline'
            id='messageField'
            name={PERSONAL_MESSAGE}
            label={intl.formatMessage(giftCardDetailsPageMessages.personalMessageOptionalLabel)}
            validationSchema={getMessageSchema(intl, isProfanityDetectionEnabled, PERSONAL_MESSAGE, giftCardDetailsPageMessages.profaneMessage)}
            helperText={
                `${intl.formatMessage(giftCardDetailsPageMessages.charactersLeftHelperText,
                    { number: remainingNumber })}`
            }
            onChange={handleMessageChange}
            multiline
            rows='3'
            customPlaceHolder='e.g. Thank you!'
        />
    );
};

PersonalMessage.propTypes = {
    intl: PropTypes.object.isRequired,
    personalMessage: PropTypes.string.isRequired,
    isProfanityDetectionEnabled: PropTypes.bool.isRequired
};

const SenderEmail = ({ intl }) => (
    <TextInputContainer
        className='pdpFormField senderEmailField textFieldOutline'
        id='senderEmailField'
        name={SENDER_EMAIL}
        label={(
            <>
                <span className='requiredAsterisk'>*</span>
                {intl.formatMessage(giftCardDetailsPageMessages.senderEmailLabel)}
            </>
        )}
        validationSchema={getEmailSchema(intl)}
        helperText={`${intl.formatMessage(giftCardDetailsPageMessages.senderEmailRemark)}`}
        customPlaceHolder='e.g. email@abc.com'
    />
);

SenderEmail.propTypes = {
    intl: PropTypes.object.isRequired
};

const SenderName = ({ intl, isProfanityDetectionEnabled }) => (
    <TextInputContainer
        className='pdpFormField senderNameField textFieldOutline'
        id='senderNameField'
        name={SENDER_NAME}
        label={(
            <>
                <span className='requiredAsterisk'>*</span>
                {intl.formatMessage(giftCardDetailsPageMessages.senderNameLabel)}
            </>
        )}
        validationSchema={getNameSchema(intl, isProfanityDetectionEnabled, SENDER_NAME, giftCardDetailsPageMessages.profaneSenderName)}
    />
);

SenderName.propTypes = {
    intl: PropTypes.object.isRequired,
    isProfanityDetectionEnabled: PropTypes.bool.isRequired
};

const SendGiftForm = (props) => {
    const { setValue, getValues, trigger } = useFormContext();
    const {
        intl,
        isProfanityDetectionEnabled,
        shareViaLinkEnabled,
        shareViaLinkDefaultEnabled,
        productDetails: {
            recipientName, recipientEmail, senderName, senderEmail, personalMessage
        } = {}
    } = props;

    const [eGiftCardShareOption, setEGiftCardShareOption] = useState(shareViaLinkDefaultEnabled ? EGIFT_DELIVERY_SHAREABLE : EGIFT_DELIVERY_EMAIL);

    const handleGiftCardDeliveryMethodChange = (event) => {
        setEGiftCardShareOption(event.target.value);
    };

    React.useEffect(() => {
        if (recipientName && !getValues(RECIPIENT_NAME)) {
            setValue(RECIPIENT_NAME, recipientName);
            trigger(RECIPIENT_NAME);
        }
        if (recipientEmail && !getValues(RECIPIENT_EMAIL)) {
            setValue(RECIPIENT_EMAIL, recipientEmail);
            trigger(RECIPIENT_EMAIL);
        }
        if (senderName && !getValues(SENDER_NAME)) {
            setValue(SENDER_NAME, senderName);
            trigger(SENDER_NAME);
        }
        if (senderEmail && !getValues(SENDER_EMAIL)) {
            setValue(SENDER_EMAIL, senderEmail);
            trigger(SENDER_EMAIL);
        }
        if (personalMessage && !getValues(PERSONAL_MESSAGE)) {
            setValue(PERSONAL_MESSAGE, personalMessage);
            trigger(PERSONAL_MESSAGE);
        }
        if (eGiftCardShareOption && !getValues(EGIFT_CARD_SHARE_OPTION)) {
            setValue(EGIFT_CARD_SHARE_OPTION, eGiftCardShareOption);
            trigger(EGIFT_CARD_SHARE_OPTION);
        }
    }, []);

    return (
        <div className='deliveryformDiv'>
            {
                (
                    shareViaLinkEnabled && (
                        <DeliveryMethodRadio
                            intl={intl}
                            eGiftCardShareOption={eGiftCardShareOption}
                            name={EGIFT_CARD_SHARE_OPTION}
                            onRadioChange={handleGiftCardDeliveryMethodChange}
                        />
                    )
                )
            }
            <InputGroupTitle
                className='toTitle'
                titleText={intl.formatMessage(giftCardDetailsPageMessages.toTitle)}
            />
            {
                eGiftCardShareOption === EGIFT_DELIVERY_EMAIL
                    ? <RecipientEmail intl={intl} />
                    : null
            }
            <RecipientName
                intl={intl}
                isProfanityDetectionEnabled={isProfanityDetectionEnabled}
            />
            <PersonalMessage
                intl={intl}
                personalMessage={personalMessage}
                isProfanityDetectionEnabled={isProfanityDetectionEnabled}
            />
            <InputGroupTitle
                className='fromTitle'
                titleText={intl.formatMessage(giftCardDetailsPageMessages.fromTitle)}
            />
            <SenderEmail intl={intl} />
            <SenderName
                intl={intl}
                isProfanityDetectionEnabled={isProfanityDetectionEnabled}
            />
        </div>
    );
};

SendGiftForm.defaultProps = {
    productDetails: {}
};

SendGiftForm.propTypes = {
    intl: intlShape.isRequired,
    productDetails: PropTypes.object,
    isProfanityDetectionEnabled: PropTypes.bool.isRequired,
    shareViaLinkEnabled: PropTypes.bool.isRequired,
    shareViaLinkDefaultEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    const isProfanityDetectionEnabled = getProfanityDetectionEnabled(state);
    const shareViaLinkDefaultEnabled = getShareViaLinkDefaultOptionEnabled(state);
    return {
        isProfanityDetectionEnabled,
        shareViaLinkDefaultEnabled
    };
};

export default connect(mapStateToProps)(injectIntl(SendGiftForm));
